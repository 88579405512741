import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
interface Patient {
  IDP: string;
  Name: string;
  Surname: string;
}

import  './patient-history-page-old';


@customElement('choose-patient-page')
export class ChoosePatientPage extends LitElement {
  patients: Patient[] = []; // Store patient data with the defined type


  selectedPatientId: string | null = null; // Store selected patient ID

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .patient-selection {
      width: 80%;
      max-height: 250px; /* Set a maximum height for the scrollable area */
      overflow-y: auto; /* Enable vertical scrolling */
      background-color: #eaeaea;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column; /* Stack patients vertically */
      align-items: flex-start; /* Align to the start */
      text-align: left; /* Align text to the left */
      font-size: 18px;
      color: #333;
    }

    .patient-item {
      padding: 8px;
      cursor: pointer;
      width: 100%;
      transition: background-color 0.2s; /* Smooth transition for hover */
    }

    .patient-item:hover {
      background-color: #cfd8dc; /* Change background on hover */
    }

    .selected {
      background-color: #1976d2; /* Highlight color for selected patient */
      color: white; /* Change text color for visibility */
    }

    button {
      width: 80%;
      margin-bottom: 16px;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    button:hover {
      background-color: #1976d2;
    }
  `;

  connectedCallback() {
    super.connectedCallback();
    this._fetchPatients();
  }

  async _fetchPatients() {
    const nurseId = sessionStorage.getItem('userId');
    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Loading...';
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '1002';
    loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';  // Optional, to give a darker background
    loadingScreen.style.padding = '20px'; // Optional, for better visual appearance
    document.body.appendChild(loadingScreen);

    // Show loading screen immediately
    loadingScreen.style.display = 'block';

    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/oldpatients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nurseid: nurseId })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data: Patient[] = await response.json(); // Parse JSON response with the defined type
      this.patients = data; // Store the fetched data in the patients array

      this.requestUpdate(); // Trigger a re-render to show the new data
      loadingScreen.style.display = 'none';
    } catch (error) {
      alert(error); // Show error message
      loadingScreen.innerText = 'loading in failed. Please check your connection and try again.'; // Show error on loading screen
    }finally {
      setTimeout(() => {
        loadingScreen.style.display = 'none'; // Always hide the loading screen after operation
      }, 2000); // Optional delay to allow the user to see the message
    }
  }

  render() {
    return html`
      <div class="container">
        <h2>Choose Patient:</h2>
        <div class="patient-selection">
          ${this.patients.map(patient => html`
            <div
              class="patient-item ${this.selectedPatientId === patient.IDP ? 'selected' : ''}"
              @click="${() => this._selectPatient(patient)}">
              ${patient.Name} ${patient.Surname} (ID: ${patient.IDP})
            </div>
          `)}
        </div>

        <button @click="${this._continueToWoundSection}" id="wound" style="display: none;">Continue to wound Section </button>
        <button @click="${this._updateHistory}" id="hist" style="display: none;">Update History </button>
        <button @click="${this._seePreviousDiagnostics}" id="diag" style="display: none;">See previous diagnostics </button>
        <button @click="${this._goBack}" id="back" style="display: block;">Back </button>
      </div>
    `;
  }

  _selectPatient(patient: Patient) {
    this.selectedPatientId = patient.IDP;
    sessionStorage.setItem('patientid', patient.IDP);
    this.requestUpdate();
    //alert(patient.IDP);
    const wound = this.shadowRoot?.getElementById('wound') as HTMLInputElement ;
    const hist = this.shadowRoot?.getElementById('hist') as HTMLInputElement ;
    const diag = this.shadowRoot?.getElementById('diag') as HTMLInputElement ;
    const back = this.shadowRoot?.getElementById('back') as HTMLInputElement ;

    wound.style.display = "block"
    hist.style.display = "block"
    diag.style.display = "block"
    back.style.display = "block"
  }

  _continueToWoundSection() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    console.log('Continue to wound section');
    const woundphot = document.createElement('wound-photo-page');
    this.replaceWith(woundphot);

  }

  _updateHistory() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    const createhistory = document.createElement('patient-history-page-old');
    this.replaceWith(createhistory);
    console.log('Update history');

  }

  _seePreviousDiagnostics() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    console.log('See previous diagnostics');
    const diagnosepage = document.createElement('diagnostic-page');
    this.replaceWith(diagnosepage);
  }

  _goBack() {
    const lastPageElement = document.createElement("patient-options-page");
        this.replaceWith(lastPageElement);
}
}
