// wound-photo-page.ts
import { LitElement, html, css } from 'lit';
import { customElement,property } from 'lit/decorators.js';
import './diagnostic-page';
import bodyImage from './body.png';

@customElement('wound-photo-page')
export class WoundPhotoPage extends LitElement {
  static properties = {
    images: { type: Array }
  };
  @property({ type: Array }) images: string[] = []; // Define images property
  @property({ type: Number }) imageWidth = 130;
  @property({ type: Number }) imageHeight = 219;

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .images-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 16px;
    }

    .image-placeholder {
      width: 100px;
      height: 100px;
      background-color: #ffffff;
      border: 1px solid #ccc;
    }

    .wound-location {
      width: var(--image-width, 145px);
      height: var(--image-height, 219px);
      background-color: #f0f0f0;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: crosshair;
    }

    .red-circle {
      width: 20px;
      height: 20px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
    }

    button {
      width: 80%;
      padding: 12px;
      background-color: #1e90ff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 16px;
      font-size: 16px;
    }

    button:hover {
      background-color: #005cbf;
    }

    .input-field {
      width: 80%;
      margin-bottom: 8px;
    }

    .input-label {
      display: block;
      font-size: 16px;
      margin-bottom: 6px;
    }

    .input-box {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .dropdown {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .read-only-box {
      background-color: #e0e0e0;
    }
  `;

  markedCoordinates: { x: number; y: number } | null = null;


  constructor() {
    super();
    this.images = []; // Initialize images array
  }

  async loadImages() {
    const loadingScreen = document.createElement('div');
     loadingScreen.innerText = 'Loading...';  // Initial message
     loadingScreen.style.color = 'white';
     loadingScreen.style.position = 'fixed';
     loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
     loadingScreen.style.top = '50%';
     loadingScreen.style.left = '50%';
     loadingScreen.style.transform = 'translate(-50%, -50%)';
     loadingScreen.style.fontSize = '24px';
     loadingScreen.style.fontWeight = 'bold';
     loadingScreen.style.zIndex = '9999';  // Ensure it's on top of everything
     loadingScreen.style.padding = '20px';
     loadingScreen.style.display = 'none';  // Initially hidden
     document.body.appendChild(loadingScreen);

    try {
      loadingScreen.style.display = 'block';  // Initially hidden
      const patientId = sessionStorage.getItem("patientid");
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/api/getWoundImages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ patientId }),
      });

      const result = await response.json();
      this.images = result; // Update images property

      console.log('Received images:', this.images);
      loadingScreen.style.display = 'none';  // Initially hidden
    } catch (error) {
      loadingScreen.style.display = 'none';  // Initially hidden
      console.error('Error fetching wound images:', error);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadImages(); // Load images when the component is connected
  }
  render() {

   // this.firstUpdated();
    return html`
    <div Class = "container">
    <h2>Previous 4 images for comparison if exist</h2>
      <div class="images-grid">
        ${this.images.map((img, index) => html`
          <div class="image-placeholder">
            <img src="${img}" alt="Wound Image ${index + 1}" style="width: 100%; height: 100%;" />
          </div>
        `)}
        ${this.images.length < 4 ?
          Array.from({ length: 4 - this.images.length }).map(() => html`
            <div class="image-placeholder">
              <p>  No Image</p>
            </div>
          `) : ''
        }
      </div>

        <div class="wound-location" @click="${this._markWound}">
  <img src="${bodyImage}" alt="Body image of wound location" style="width: 100%; height: 100%;" />
  ${this.markedCoordinates
    ? html`
        <div
          class="red-circle"
          style="left:${this.markedCoordinates.x}px; top:${this.markedCoordinates.y}px;"
          @click="${this._removeMark}"
        ></div>
      `
    : ''}
</div>

        <!-- Input Fields -->

        <div class="input-field">
          <label class="input-label">Wound Location</label>
          <input type="text" class="input-box" id="loc"/>
        </div>


        <div class="input-field">
          <label class="input-label">Wound Depth</label>
          <input type="text" class="input-box" id="depth"/>
        </div>



        <div class="input-field">
          <label class="input-label">Wound Moist Level</label>
          <select class="dropdown" id="moist">
            <option value="Dry">Dry</option>
            <option value="Moist">Moist</option>
            <option value="Wet">Wet</option>
          </select>
        </div>

        <div class="input-field">
          <label class="input-label">Wound Exudate Type</label>
          <select class="dropdown" id="extyp">
            <option value="Serous">Serous</option>
            <option value="Sanguineous">Sanguineous</option>
            <option value="Purulent">Purulent</option>
          </select>
        </div>

        <div class="input-field">
          <label class="input-label">Wound Exudate Level</label>
          <select class="dropdown" id="exlev">
           <option value="Absent">Absent</option>
            <option value="Low">Low</option>
            <option value="Moderate">Moderate</option>
            <option value="High">High</option>
          </select>
        </div>



        <!-- Buttons -->
        <button @click="${this._takePhoto}">Take photo</button>

            <!-- Read-only Predicted Diagnostic Field -->
        <div class="input-field">
          <label class="input-label">Predicted Diagnosis</label>
          <input type="text" class="input-box read-only-box" readonly id="diag" value="0"/>
        </div>

        <!-- Text Input for Wound Size and Change -->
        <div class="input-field">
          <label class="input-label">Wound Size</label>
          <input type="text" class="input-box read-only-box" readonly id = "size" value="0"/>
        </div>

       <div class="input-field" style="display: none;">
  <label class="input-label">Wound Change</label>
  <input type="text" class="input-box read-only-box" readonly id="change" value="0" />
</div>

        <button @click="${this._continue}" id = "cont" style="display: none;" >Continue</button>
        <button @click="${this._goBack}" >Back</button>
      </div>
    `;
  }
//   firstUpdated(){
// this._photoload();
//   }

//   async _photoload(){
//      // Create loading screen element
//      const loadingScreen = document.createElement('div');
//      loadingScreen.innerText = 'Loading...';  // Initial message
//      loadingScreen.style.color = 'white';
//      loadingScreen.style.position = 'fixed';
//      loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
//      loadingScreen.style.top = '50%';
//      loadingScreen.style.left = '50%';
//      loadingScreen.style.transform = 'translate(-50%, -50%)';
//      loadingScreen.style.fontSize = '24px';
//      loadingScreen.style.fontWeight = 'bold';
//      loadingScreen.style.zIndex = '9999';  // Ensure it's on top of everything
//      loadingScreen.style.padding = '20px';
//      loadingScreen.style.display = 'none';  // Initially hidden
//      document.body.appendChild(loadingScreen);

//      try {
//       loadingScreen.style.display = 'block';  // Initially hidden
//       const patientId = sessionStorage.getItem("patientid");
//       const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/api/getWoundImages', {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ patientId }),
//       });

//       if (!response.ok) {
//           throw new Error('Network response was not ok');
//       }

//       const imagePaths = await response.json();
//       alert('Received image paths:');
//       alert(imagePaths);

//       const imagePlaceholders = document.querySelectorAll('.image-placeholder');

//       imagePlaceholders.forEach((placeholder, index) => {
//         alert("works")
//           if (imagePaths[index]) {
//             alert("yes")
//               placeholder.innerHTML = `<img src="${imagePaths[index]}" alt="Wound Image ${index + 1}" style="max-width: 100%; max-height: 100%;" />`;
//           } else {
//               placeholder.innerHTML = '<p>No Image Available</p>';
//           }
//       });
//       loadingScreen.style.display = 'none';  // Initially hidden
//   } catch (error) {
//     loadingScreen.style.display = 'none';  // Initially hidden
//       console.error('Error loading images:', error);
//       alert('Error loading images');
//   }
// }



  _markWound(event: MouseEvent) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left - 10;
    const y = event.clientY - rect.top - 10;

    // Only mark if there is no existing mark
    if (!this.markedCoordinates) {
      this.markedCoordinates = { x, y };
      this.requestUpdate(); // Trigger re-render to show the mark
    }
  }

  _removeMark(event: MouseEvent) {
    event.stopPropagation();  // Prevent the click from triggering the _markWound event
    this.markedCoordinates = null;
    this.requestUpdate(); // Trigger re-render to remove the mark
  }

  _takePhoto() {
    const videoElement = document.createElement('video');
    const canvasElement = document.createElement('canvas');
    const context = canvasElement.getContext('2d');

    // Button to capture the photo
    const takePhotoButton = document.createElement('button');
    takePhotoButton.innerText = 'Take Photo';
    takePhotoButton.style.position = 'fixed';
    takePhotoButton.style.bottom = '20px';
    takePhotoButton.style.left = '50%';
    takePhotoButton.style.transform = 'translateX(-50%)';
    takePhotoButton.style.padding = '10px 20px';
    takePhotoButton.style.fontSize = '18px';
    takePhotoButton.style.zIndex = '1001';
    takePhotoButton.style.color = 'white';
    takePhotoButton.style.backgroundColor = '#1e90ff'; // On top of the video

    // Buttons for retake and use
    const retakeButton = document.createElement('button');
    retakeButton.innerText = 'Retake';
    retakeButton.style.display = 'none'; // Initially hidden
    retakeButton.style.position = 'fixed';
    retakeButton.style.bottom = '60px';
    retakeButton.style.left = '5%';
    retakeButton.style.padding = '10px 20px';
    retakeButton.style.fontSize = '18px';
    retakeButton.style.zIndex = '1001';
    retakeButton.style.backgroundColor = '#1e90ff';
    retakeButton.style.color = 'white';

    const useButton = document.createElement('button');
    useButton.innerText = 'Use Photo';
    useButton.style.display = 'none'; // Initially hidden
    useButton.style.position = 'fixed';
    useButton.style.bottom = '60px';
    useButton.style.right = '5%';
    useButton.style.color = 'white';
    useButton.style.padding = '10px 20px';
    useButton.style.fontSize = '18px';
    useButton.style.zIndex = '1001';
    useButton.style.backgroundColor = '#1e90ff';

    // Loading screen
    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Saving photo...';
    loadingScreen.style.display = 'none'; // Initially hidden
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '1002';
    document.body.appendChild(loadingScreen);

    // Apply full-screen styles to the video element
    videoElement.style.position = 'fixed';
    videoElement.style.top = '0';
    videoElement.style.left = '0';
    videoElement.style.width = '100vw';  // Full viewport width
    videoElement.style.height = '100vh'; // Full viewport height
    videoElement.style.zIndex = '1000';  // Ensure it appears on top
    videoElement.style.objectFit = 'cover'; // Ensure the video covers the screen

    // Function to start the camera with environment (back) first
    const startCamera = async () => {
        let constraints = {
            video: {
                facingMode: { exact: 'environment' },// Try to access the back camera
                width: { ideal: 1280 }, // Example width
                height: { ideal: 720 }, // Example height
                // You might include a specific aspect ratio
                aspectRatio: { ideal: 16 / 9 }
            }
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoElement.srcObject = stream;
            videoElement.play();

            // Display the video and capture button
            document.body.appendChild(videoElement);
            document.body.appendChild(takePhotoButton);

            // Handle photo capture
            takePhotoButton.addEventListener('click', () => {
                // Set canvas size to video size
                canvasElement.width = videoElement.videoWidth;
                canvasElement.height = videoElement.videoHeight;

                // Draw the current video frame onto the canvas
                context?.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                // Hide video and show the captured photo
                videoElement.style.display = 'none';
                takePhotoButton.style.display = 'none';
                document.body.appendChild(canvasElement); // Show captured photo
                canvasElement.style.position = 'fixed';
                canvasElement.style.top = '0';
                canvasElement.style.left = '0';
                canvasElement.style.width = '100vw';
                canvasElement.style.height = '100vh';
                canvasElement.style.zIndex = '1000';

                // Show retake and use buttons
                retakeButton.style.display = 'block';
                useButton.style.display = 'block';
                document.body.appendChild(retakeButton);
                document.body.appendChild(useButton);
            });

            // Handle retake photo
            retakeButton.addEventListener('click', () => {
                // Reset canvas and hide it
                loadingScreen.style.display = 'none';
                document.body.removeChild(canvasElement); // Remove the canvas element

                // Show the video feed and take photo button again
                videoElement.style.display = 'block';
                retakeButton.style.display = 'none';  // Hide retake and use buttons
                useButton.style.display = 'none';
                takePhotoButton.style.display = 'block'; // Show capture button again
            });

            // Handle use photo
            useButton.addEventListener('click', () => {
                // Show loading screen
                loadingScreen.innerText = 'Saving photo...';
                loadingScreen.style.display = 'block';

                // Convert the canvas content to a base64 string
                const photoDataUrl = canvasElement.toDataURL('image/png');

                // Send the base64 string to the back-end
                fetch('https://058d81c6-3000.inc1.devtunnels.ms/upload-photo', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ imageData: photoDataUrl  })
                })
                .then(response => response.json())
                .then(data => {

                 //alert(data)
                    // Hide loading screen
                    loadingScreen.style.display = 'none';

                    // Stop the video stream and remove the video element
                    stream.getTracks().forEach(track => track.stop());
                    document.body.removeChild(videoElement);
                    document.body.removeChild(canvasElement);
                    document.body.removeChild(retakeButton);
                    document.body.removeChild(useButton);
                    const size = this.shadowRoot?.getElementById('size') as HTMLInputElement;
                    size.value = data.output.total_area;
                    const diag = this.shadowRoot?.getElementById('diag') as HTMLInputElement;
                    //alert(data.output2.predicted_class)
                    diag.value = data.output2.predicted_class
                    sessionStorage.setItem("WoundFile",data.filePath);
                })
                .catch(error => {
                    console.error('Error uploading the photo:', error);
                    loadingScreen.innerText = 'Error saving photo. Please try again.';
                    setTimeout(() => {
                      loadingScreen.style.display = 'none'; // Always hide the loading screen after operation
                    }, 2000);  // Update loading screen text on error
                });
            });
        } catch (error) {
            console.error('Error accessing the back camera:', error);

            // Fallback to front camera
            constraints.video = { facingMode: { exact: 'user' },width: { ideal: 1280 }, // Example width
            height: { ideal: 720 }, // Example height
            // You might include a specific aspect ratio
            aspectRatio: { ideal: 16 / 9 }}; // Try to access the front camera
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                videoElement.srcObject = stream;
                videoElement.play();

                // Display the video and capture button
                document.body.appendChild(videoElement);
                document.body.appendChild(takePhotoButton);

                // Handle photo capture and other event listeners can remain the same
                // Re-using existing event listeners for takePhotoButton, retakeButton, and useButton
                // Handle photo capture
                takePhotoButton.addEventListener('click', () => {
                    // Set canvas size to video size
                    canvasElement.width = videoElement.videoWidth;
                    canvasElement.height = videoElement.videoHeight;

                    // Draw the current video frame onto the canvas
                    context?.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                    // Hide video and show the captured photo
                    videoElement.style.display = 'none';
                    takePhotoButton.style.display = 'none';
                    document.body.appendChild(canvasElement); // Show captured photo
                    canvasElement.style.position = 'fixed';
                    canvasElement.style.top = '0';
                    canvasElement.style.left = '0';
                    canvasElement.style.width = '100vw';
                    canvasElement.style.height = '100vh';
                    canvasElement.style.zIndex = '1000';

                    // Show retake and use buttons
                    retakeButton.style.display = 'block';
                    useButton.style.display = 'block';
                    document.body.appendChild(retakeButton);
                    document.body.appendChild(useButton);
                });

                // Handle retake photo
                retakeButton.addEventListener('click', () => {
                    // Reset canvas and hide it
                    document.body.removeChild(canvasElement); // Remove the canvas element

                    // Show the video feed and take photo button again
                    videoElement.style.display = 'block';
                    retakeButton.style.display = 'none';  // Hide retake and use buttons
                    useButton.style.display = 'none';
                    takePhotoButton.style.display = 'block'; // Show capture button again
                });

                // Handle use photo
                useButton.addEventListener('click', () => {
                    // Show loading screen
                    loadingScreen.innerText = 'Saving photo...';
                    loadingScreen.style.display = 'block';

                    // Convert the canvas content to a base64 string
                    const photoDataUrl = canvasElement.toDataURL('image/png');

                    // Send the base64 string to the back-end
                    fetch('https://058d81c6-3000.inc1.devtunnels.ms/upload-photo', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ imageData: photoDataUrl })
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Photo saved at:', data.filePath);
                        //alert(data.output2.predicted_class)
                        // Hide loading screen
                        loadingScreen.style.display = 'none';

                        // Stop the video stream and remove the video element
                        stream.getTracks().forEach(track => track.stop());
                        document.body.removeChild(videoElement);
                        document.body.removeChild(canvasElement);
                        document.body.removeChild(retakeButton);
                        document.body.removeChild(useButton);

                        const size = this.shadowRoot?.getElementById('size') as HTMLInputElement;
                        size.value = data.output.total_area;
                        const diag = this.shadowRoot?.getElementById('diag') as HTMLInputElement;
                        diag.value = data.output2.predicted_class
                        //alert(data.output2.predicted_class)
                        sessionStorage.setItem("WoundFile",data.filePath);
                    })
                    .catch(error => {
                        console.error('Error uploading the photo:', error);
                        //alert(error)
                        loadingScreen.innerText = 'Error saving photo. Please try again.'; // Update loading screen text on error

                        setTimeout(() => {
                          loadingScreen.style.display = 'none'; // Always hide the loading screen after operation
                          loadingScreen.innerText = 'Saving photo...';
                        }, 2000); // Optional delay to allow the user to see the message
                    });

                });
            } catch (error) {
                console.error('Error accessing the front camera:', error);
            }
        }
    };

    // Start the camera
    startCamera();
    const cont = this.shadowRoot?.getElementById('cont') as HTMLInputElement;
                    cont.style.display ="block";
}


  async _continue() {
    this._saveMarkedImage()
    sessionStorage.setItem('lastPage','wound-photo-page');
    const diagnosepage = document.createElement('diagnostic-page');

    const depth = (this.shadowRoot?.getElementById('depth') as HTMLInputElement)?.value;
    const moist = (this.shadowRoot?.getElementById('moist') as HTMLInputElement)?.value;
    const loc = (this.shadowRoot?.getElementById('loc') as HTMLInputElement)?.value;
    const extyp = (this.shadowRoot?.getElementById('extyp') as HTMLInputElement)?.value;
    const exlev = (this.shadowRoot?.getElementById('exlev') as HTMLInputElement)?.value;
    const size = (this.shadowRoot?.getElementById('size') as HTMLInputElement)?.value;
    const change = (this.shadowRoot?.getElementById('change') as HTMLInputElement)?.value;
    const diag = (this.shadowRoot?.getElementById('diag') as HTMLInputElement)?.value;
    const locfile = sessionStorage.getItem('LocFile');
    const woundfile = sessionStorage.getItem('WoundFile');
    const IDP = sessionStorage.getItem('patientid');



    const woundData = {
      depth,
      moist,
      loc,
      extyp,
      exlev,
      size,
      change,
      diag,
      IDP,
      woundfile,
      locfile,
  };


  fetch('https://058d81c6-3000.inc1.devtunnels.ms/insertWoundInfo', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(woundData),
})
.then(response => {
    if (!response.ok) {
        // If the response status is not OK (status 200-299), throw an error
        return response.json().then(errorData => {
            throw new Error(errorData.message || 'Something went wrong');
        });
    }
    return response.json();
})
.then(data => {
    console.log('Success:', data);
    // Replace the current page with the diagnose page only on success
    this.replaceWith(diagnosepage);
})
.catch((error) => {
    // Handle any errors that occur during the fetch
    //alert(error.message)
    //alert(error)
    console.error('Error:', error.message || error);
});
  }

  _saveMarkedImage() {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set canvas size to match the body image
    const img = new Image();
    img.src = bodyImage;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the body image onto the canvas
      context?.drawImage(img, 0, 0);

      // Draw the red circle (wound mark) onto the canvas if coordinates are marked
      if (this.markedCoordinates) {
        context!.fillStyle = 'red';
        context!.beginPath();
        context!.arc(this.markedCoordinates.x + 10, this.markedCoordinates.y+10, 10, 0, Math.PI * 2);
        context!.fill();
      }

      // Convert the canvas to a base64 image string
      const imageData = canvas.toDataURL('image/png');

      // Send the image data to the server
      fetch('https://058d81c6-3000.inc1.devtunnels.ms/save-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageData }),
      })
        .then(response => response.json())
        .then(data => {
         //alert(data.filePath);
         sessionStorage.setItem('LocFile', data.filePath);
        })
        .catch(error => {
          console.error('Error saving image:', error);
        });
    };
  }

  _goBack() {
      const lastPage = sessionStorage.getItem('lastPage');
if (lastPage) {
    const lastPageElement = document.createElement(lastPage);
    this.replaceWith(lastPageElement);
}
}
}
