// diagnostic-page.ts
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import './patient-options-page';
@customElement('diagnostic-page')
export class DiagnosticPage extends LitElement {
  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    // .result-box {
    //   width: 80%;
    //   height: 100px;
    //   background-color: #f0f0f0;
    //   margin-bottom: 16px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   font-size: 18px;
    //   color: #333;
    // }

  .result-box {
  width: 80%;
  height: 100px;
  background-color: #f0f0f0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column; /* Ensure content stacks vertically */
  align-items: flex-start; /* Align text to the start (top left) */
  justify-content: flex-start;
  font-size: 18px;
  color: #333;
  overflow-y: auto; /* Enable vertical scroll */
  padding: 8px; /* Add padding for readability */
  box-sizing: border-box; /* Include padding in height */
}

    button {
      width: 80%;
      padding: 12px;
      background-color: #1e90ff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 16px;
      font-size: 16px;
    }

    button:hover {
      background-color: #005cbf;
    }

    h3 {
      margin-top: 0;
      text-align: center;
    }
  `;

  render() {
    this._loadall()
    return html`
      <div class="container">
        <h3>Diagnostic page</h3>
        <h3>History</h3>
        <div class="result-box" id = "hist result">History Results</div>
        <h3>Diagnostic of Image</h3>
        <div class="result-box" id = "im result">Image Results</div>
        <h3>Treatment Summary</h3>
        <div class="result-box" id ="treat result">Prescribed treatment plan</div>

        <button @click="${this._continue}">Continue</button>
        <button @click="${this._goBack}">Back</button>
      </div>
    `;
  }

  async _loadall(){

    let treatmentPlan = [];
    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Loading...';  // Initial message
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '9999';  // Ensure it's on top of everything
    loadingScreen.style.padding = '20px';
    loadingScreen.style.display = 'none';  // Initially hidden
    document.body.appendChild(loadingScreen);

      try {
        loadingScreen.style.display = 'block';  // Initially hidden
        const patientId = sessionStorage.getItem("patientid");
        const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/getWounddata', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ patientId }),
        });

        const result = await response.json();
        const data = result[0];

        let change = "";
if (result[1]){
if (data.WoundSize > result[1].WoundSize) {
  change = "Wound Increasing in size";
} else if (data.WoundSize === result[1].WoundSize) {
  change = "Wound stationary in size";
} else if (data.WoundSize < result[1].WoundSize) {
  change = "Wound Decreasing in size";
}
}else{
  change = "First Wound assesment"
}

        const woundSummary = `
  Patient ID: ${data.IDP}
  Wound Depth: ${data.WoundDepth}
  Wound Location: ${data.WoundLocation ? data.WoundLocation : 'Location not specified'}
  Wound Size: ${data.WoundSize ? data.WoundSize : 'Size not available'}
  Wound Change: ${change}
  Moisture Level: ${data.WoundMoistLevel ? data.WoundMoistLevel : 'Not specified'}
  Exudate Type: ${data.WoundExudateT ? data.WoundExudateT : 'Not specified'}
  Exudate Level: ${data.WoundExudateL ? data.WoundExudateL : 'Not specified'}
  Predicted Diagnosis: ${data.PredictedDiag !== '0' ? data.PredictedDiag : 'No diagnosis predicted'}
`;

        const woundDateElement1 = this.shadowRoot?.getElementById('im result') as HTMLElement;



if (woundDateElement1) {
  woundDateElement1.innerText = woundSummary;  // Set the text content of the div
  woundDateElement1.scrollTop = 0;  // Scroll to the top
}


      //////////////////////////////////////////////////////////////////////////////////////////////////////// Patient Info
      treatmentPlan.push(`Patient ID: ${data.IDP}`);
      treatmentPlan.push(`Wound Depth: ${data.WoundDepth}`);
      treatmentPlan.push(`Wound Location: ${data.WoundLocation ? data.WoundLocation : 'Location not specified'}`);
      treatmentPlan.push(`Wound Size: ${data.WoundSize ? data.WoundSize : 'Size not available'}`);
      treatmentPlan.push(`Wound Change: ${change}`);
      treatmentPlan.push(`Moisture Level: ${data.WoundMoistLevel ? data.WoundMoistLevel : 'Not specified'}`);
      treatmentPlan.push(`Exudate Type: ${data.WoundExudateT ? data.WoundExudateT : 'Not specified'}`);
      treatmentPlan.push(`Exudate Level: ${data.WoundExudateL ? data.WoundExudateL : 'Not specified'}`);
      treatmentPlan.push(`Predicted Diagnosis: ${data.PredictedDiag !== '0' ? data.PredictedDiag : 'No diagnosis predicted'}`);


      if (data.PredictedDiag == "Diabetic"){
        ///////////////////////////////////////////////////////////////////////////////////
              treatmentPlan.push(`Traetment suggested: off-loading required. Method: crutches or wheelchair.  `);
              treatmentPlan.push(`Contradictions: `);
              }else{
                if (data.PredictedDiag == "Pressure"){
        ///////////////////////////////////////////////////////////////////////////////////
        treatmentPlan.push(`Traetment suggested: Pressure Relief (Offloading) required. Method: turn patient regulary.  `);
        treatmentPlan.push(`Contradictions: `);
                }else{ if (data.PredictedDiag =="Venous or Arterial"){
        ///////////////////////////////////////////////////////////////////////////////////
        treatmentPlan.push(`Traetment suggested: if Venous Compression therapy if Arterial Circulation improvements.  `);
        treatmentPlan.push(`Contradictions: `);
                }

                }

              }




        loadingScreen.style.display = 'none';  // Initially hidden
      } catch (error) {
        loadingScreen.style.display = 'none';  // Initially hidden
        console.error('Error fetching wound images:', error);

    }

    try {
      loadingScreen.style.display = 'block';
      const patientID = sessionStorage.getItem("patientid");
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/patient-history-old1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          patientID,
          }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
     // alert(JSON.stringify(data))
    // Get the most recent result
const latestData = data[0];

const age = latestData.Age;
const date = new Date(latestData.DateOfWound);
const woundOccurrence = date.toISOString().split('T')[0]; // Gets 'YYYY-MM-DD'
const woundHistory = latestData.WoundTreatmentHist;
const haemoglobin = latestData.PlasmaHAEMOGLOBIN;

const woundtime = this._calculateWoundDuration(woundOccurrence);

const summary = [];

// Age check
if (age < 5 || age > 70) {
    summary.push(`Age is ${age}. Contraindication for four-layered compression bandage.`);
    treatmentPlan.push(`Age is ${age}. Contraindication for four-layered compression bandage.`);
}

// Duration of wound
if (parseFloat(woundtime) > 3) {
    summary.push(`Wound has lasted for ${woundtime} months. Diagnosed as chronic wound.`);
}

// BMI check
if (latestData.BMI < 18.5) {
    summary.push(`BMI is ${latestData.BMI}. Low BMI; check serum albumin.`);
}else if (latestData.BMI > 24.9){
  summary.push(`BMI is ${latestData.BMI}. High BMI.`);
}

// Serum Albumin check
if (latestData.SerumAlbumin < 20 && latestData.SerumAlbumin > 0) {
    summary.push(`Serum albumin is critically low at ${latestData.SerumAlbumin} g/L. Refer to dietitian.`);
    treatmentPlan.push(`Serum albumin is critically low refer to dietitian as treatment.`);
} else if (latestData.SerumAlbumin >= 20 && latestData.SerumAlbumin < 30) {
    summary.push(`Serum albumin is low at ${latestData.SerumAlbumin} g/L. Prescribe high protein diet.`);
    treatmentPlan.push(`Serum albumin is low prescribe High protien diet.`);
} else if (latestData.SerumAlbumin=0){

}

const [systolic, diastolic] = latestData.BloodPressure.split('/').map(Number);

// Blood pressure check
if (systolic < 90 || systolic > 140 || diastolic < 60 || diastolic > 90) {
    summary.push(`Abnormal blood pressure: ${latestData.BloodPressure} mmHg.`);
}

// Blood Glucose check
if (latestData.BloodGlucose > 10) {
    summary.push(`Blood glucose is high at ${latestData.BloodGlucose} mmol/L. Refer for blood glucose control.`);
    treatmentPlan.push(`Blood glucose is high,Refer for blood glucose control .`);
}

// Plasma Haemoglobin check
const isMale = latestData.SEX === 'male';
const haemoglobinThreshold = isMale ? [13.8, 17.2] : [12.1, 15.1];
if (haemoglobin < haemoglobinThreshold[0]) {
    if (haemoglobin < 8 && haemoglobin > 0) {
        summary.push(`Haemoglobin critically low at ${haemoglobin} g/dL. Refer for blood transfusion.`);
        treatmentPlan.push(`Haemoglobin critically low,Refer for blood transfusion.`);
    } else if (haemoglobin < haemoglobinThreshold[0] && haemoglobin > 0) {
        summary.push(`Haemoglobin low at ${haemoglobin} g/dL. Consider Ferrous Sulphate supplement.`);
        treatmentPlan.push(`Haemoglobin low,Consider Ferrous Sulphate supplement.`);
    }
}

const comorbidities = [
  { key: 'Diabetes', label: 'diabetes' },
  { key: 'Hypertension', label: 'hypertension' },
  { key: 'CardiacFailure', label: 'cardiac failure' },
  { key: 'ChronicKidneyDisease', label: 'chronic kidney disease' },
  { key: 'Malnutrition', label: 'malnutrition' },
  { key: 'ChronicLungDisease', label: 'chronic lung disease' },
  { key: 'ImmuneCompromise', label: 'immune compromise' },
  { key: 'HaematopoieticDisease', label: 'haematopoietic disease' }
];

comorbidities.forEach(condition => {
  if (latestData[condition.key]) {
      summary.push(`Patient has ${condition.label}.`);
  }
});

// Ankle-Brachial Pressure Index check
const ankleBrachialIndex = latestData.AnklePressure / latestData.BrachialPressure;
if (ankleBrachialIndex > 1.3) {
    summary.push(`Ankle-Brachial Index > 1.3 and is ${ankleBrachialIndex}`);

} else if (ankleBrachialIndex < 0.8 && ankleBrachialIndex > 0) {
    summary.push(`Ankle-Brachial Index < 0.8 and is ${ankleBrachialIndex}.`);
    treatmentPlan.push(`Ankle-Brachial Index <0.8  referrel to a vascular specialist, sharp debridement contraindicated, and compression therapy contraindicated.`);

}else if (ankleBrachialIndex > 0.8 && ankleBrachialIndex < 0.9 ) {
  summary.push(`Ankle-Brachial Index > 0.8 & <0.9 and is ${ankleBrachialIndex}.`);
  treatmentPlan.push(`Ankle-Brachial Index > 0.8 & <0.9 use the four-layer compression bandage system with caution (due to compromised arterial supply).`);
}

// Toe Pressure Index check
if (latestData.ToePressureIndex < 50 && latestData.ToePressureIndex>0) {
    summary.push(`Toe pressure index < 50 mmHg. Referral to vascular specialist recommended. Toe pressure index is ${latestData.ToePressureIndex} mmHg.`);
    treatmentPlan.push(`Toe pressure index < 50 mmHg referral to a vascular specialist recomended, sharp debridement contraindicated, and compromised arterial supply, care with compression therapy.`);


  }

// Glomerular Filtration Rate check
if (latestData.GlomerularFiltrationRate < 45 && latestData.GlomerularFiltrationRate >0) {
    summary.push(`Severely decreased kidney function. GFR is below 45 ml/min it is ${latestData.GlomerularFiltrationRate}.`);
}

// HIV and CD4 count check
if (latestData.HIV && latestData.CD4Count < 200 && latestData.CD4Count >0) {
    summary.push(`HIV with CD4 count below 200 cells/mm³ count is ${latestData.CD4Count} cells/mm³.`);
}

if (latestData.Haemophilia){
  treatmentPlan.push(`Haemophilia- sharp debridement is contraindicated`);
}

// Nail and skin conditions
const nailConditions = ['IngrownNail', 'FungalInfection', 'InfectionOfTissue', 'NailAtrophy', 'DeformedNail'];
nailConditions.forEach(condition => {
    if (latestData[condition]) summary.push(`${condition.replace(/([A-Z])/g, ' $1')}`);
});

const skinConditions = ['Xerosis', 'Fissures', 'TineaPedis', 'PlantarWart', 'Swelling', 'Callus', 'HardCorn', 'SoftCorn', 'Blister'];
skinConditions.forEach(condition => {
    if (latestData[condition]) summary.push(`${condition.replace(/([A-Z])/g, ' $1')} - Skin Condition`);
});

// Medications & Treatments
const meds = ['ImmunoSuppressive', 'Cytotoxic', 'Corticosteroid', 'Radiotherapy'];
meds.forEach(med => {
    if (latestData[med]) summary.push(`${med.replace(/([A-Z])/g, ' $1')} - possible delayed healing.`);
});

const woundDateElement = this.shadowRoot?.getElementById('hist result') as HTMLElement;

// Example usage:
summary.push("Previous Treatment Notes:");
summary.push(woundHistory);

const patientSummary = summary.join('\n');  // Join array elements into a string with new lines

if (woundDateElement) {
    woundDateElement.innerText = patientSummary;  // Set the text content of the div
    woundDateElement.scrollTop = 0;  // Scroll to the top
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const treatmentPlan1=treatmentPlan.join('\n');

const woundDateElement11 = this.shadowRoot?.getElementById('treat result') as HTMLElement;

if (woundDateElement11) {
  woundDateElement11.innerText = treatmentPlan1;  // Set the text content of the div
  woundDateElement11.scrollTop = 0;  // Scroll to the top
}


loadingScreen.style.display = 'none';  // Initially hidden
      // Repeat for other fields
    } catch (error) {
      loadingScreen.style.display = 'none';  // Initially hidden
      alert(error)
    }


  }

  _calculateWoundDuration(woundOccurrence: string | Date) {

      const woundDate = woundOccurrence;


        const woundDateObj = new Date(woundDate);
        const currentDate = new Date();
        const duration = ((currentDate.getFullYear() - woundDateObj.getFullYear()) * 12
                       + (currentDate.getMonth() - woundDateObj.getMonth()))*4;
        return  duration.toString();




  }


  _continue() {
    console.log('Continuing to the next step...');
    // Logic to navigate to the next page or perform an action

    sessionStorage.setItem('lastPage','diagnostic-page');
    const patientOptions = document.createElement('patient-options-page');
    this.replaceWith(patientOptions);
  }

  _goBack() {

    const lastPage = sessionStorage.getItem('lastPage');
    if (lastPage) {
        const lastPageElement = document.createElement(lastPage);
        this.replaceWith(lastPageElement);
    }
  }
}
