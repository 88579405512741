import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './patient-options-page';

@customElement('auth-page')
export class AuthPage extends LitElement {

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
    }

    .form {
      width: 300px;
      margin-bottom: 30px;
    }

    .form input {
      width: 100%;
      padding: 10px;
      margin: 8px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .button {
      width: 100%;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10px;
    }

    .button:hover {
      background-color: #1976d2;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h2>Already have an account?</h2>
        <div class="form">
          <input id="sign-in-username" type="text" placeholder="Username" />
          <input id="sign-in-password" type="password" placeholder="Password" />
          <button class="button" @click=${this.signIn}>Sign in</button>
        </div>

        <h2>Create an account?</h2>
        <div class="form">
          <input id="sign-up-username" type="text" placeholder="Username" />
          <input id="sign-up-password" type="password" placeholder="Password" />
          <input id="sign-up-Name" type="text" placeholder="Name" />
          <input id="sign-up-SurName" type="text" placeholder="SurName" />
          <input id="sign-up-Rank" type="text" placeholder="Rank" />
          <button class="button" @click=${this.signUp}>Sign up</button>
        </div>
      </div>
    `;
  }

  async signIn() {
    const usernameInput = this.shadowRoot?.getElementById('sign-in-username') as HTMLInputElement;
    const passwordInput = this.shadowRoot?.getElementById('sign-in-password') as HTMLInputElement;

    // Create and style the loading screen
    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Loading...';
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '1002';
    loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';  // Optional, to give a darker background
    loadingScreen.style.padding = '20px'; // Optional, for better visual appearance
    document.body.appendChild(loadingScreen);

    // Show loading screen immediately
    loadingScreen.style.display = 'block';

    // Get the value of the inputs
    const username = usernameInput?.value;
    const password = passwordInput?.value;
    console.log('Username:', username);

    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/sign-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      const result = await response.json();
      if (result.success) {
        const userId = result.id;
        const nurname = result.name;
        sessionStorage.setItem('nurName', nurname);
        sessionStorage.setItem('userId', userId);
        loadingScreen.style.display = 'none'; // Hide loading screen on success
        this.navigateToPage2();
      } else {
        alert(result.message); // Show error message
        loadingScreen.innerText = 'Error. Please try again.'; // Update loading screen text on error
      }
    } catch (error) {
      console.error('Sign in failed:', error);
      loadingScreen.innerText = 'Sign in failed. Please check your connection and try again.'; // Show error on loading screen
    } finally {
      setTimeout(() => {
        loadingScreen.style.display = 'none'; // Always hide the loading screen after operation
      }, 2000); // Optional delay to allow the user to see the message
    }
  }

  async signUp() {

    const usernameInput = this.shadowRoot?.getElementById('sign-up-username') as HTMLInputElement;
    const passwordInput = this.shadowRoot?.getElementById('sign-up-password') as HTMLInputElement;
    const nameInput = this.shadowRoot?.getElementById('sign-up-Name') as HTMLInputElement;
    const surnameInput = this.shadowRoot?.getElementById('sign-up-SurName') as HTMLInputElement;
    const rankInput = this.shadowRoot?.getElementById('sign-up-Rank') as HTMLInputElement;


    // Get the value of the inputs
    const username = usernameInput?.value;
    const password = passwordInput?.value;
    const name = nameInput?.value;
    const surname = surnameInput?.value;
    const rank = rankInput?.value;


    const loadingScreen = document.createElement('div');
    loadingScreen.innerText = 'Loading...';
    loadingScreen.style.color = 'white';
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '50%';
    loadingScreen.style.left = '50%';
    loadingScreen.style.transform = 'translate(-50%, -50%)';
    loadingScreen.style.fontSize = '24px';
    loadingScreen.style.fontWeight = 'bold';
    loadingScreen.style.zIndex = '1002';
    loadingScreen.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';  // Optional, to give a darker background
    loadingScreen.style.padding = '20px'; // Optional, for better visual appearance
    document.body.appendChild(loadingScreen);

    // Show loading screen immediately
    loadingScreen.style.display = 'block';


    console.log('Username:', username);
    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/sign-up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({ username, password,name,surname,rank})
      });

      const result = await response.json();
      if (result.success) {
        const userId = result.id;
        const nurname = result.name;
        sessionStorage.setItem('nurName', nurname);
        sessionStorage.setItem('userId', userId);
        loadingScreen.style.display = 'none'; // Hide loading screen on success

        this.navigateToPage2();
      } else {
        alert(result.message); // Show error message
        loadingScreen.innerText = 'Error. Please try again.';
      }
    } catch (error) {
      console.error('Sign up failed:', error);
      loadingScreen.innerText = 'Sign in failed. Please check your connection and try again.'; // Show error on loading screen
    } finally {
      setTimeout(() => {
        loadingScreen.style.display = 'none'; // Always hide the loading screen after operation
      }, 2000); // Optional delay to allow the user to see the message
    }
  }

  navigateToPage2() {

    const patientOptions = document.createElement('patient-options-page');
    this.replaceWith(patientOptions);
  }
}
