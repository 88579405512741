// patient-history-page.ts
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './wound-photo-page';


@customElement('patient-history-page')
export class PatientHistoryPage extends LitElement {
  isdeformitiesVisible: boolean = false;
  static styles = css`
    .container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
    }
    .scrollable-area {
      width: 80%;
      height: 400px;
      overflow-y: scroll;
      margin-bottom: 16px;
      background-color: #f0f0f0;
      padding: 8px;
      border-radius: 4px;
    }
    .scrollable-area input, .scrollable-area select, .scrollable-area textarea {
      width: 100%;
      padding: 8px;
      margin-bottom: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .scrollable-area label {
      display: block;
      margin-bottom: 6px;
      font-weight: bold;
    }
    .checkbox-container {
      display: flex;

    }
    .checkbox-container label {
      margin-left: 8px;
    }
    .checkbox-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px; /* Adjust this value to control vertical spacing */
    }
    button {
      width: 300px;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin: 15px 0;
    }
  `;

  render() {

   this.firstUpdated();

    return html`
      <div class="container">
        <h2>Patient History, Symptoms, and Test Results</h2>
        <div class="scrollable-area">

          <label for="age">Age:</label>
          <input type="text" id="age" value="0" readonly>

          <label for="sex">Sex:</label>
          <select id="sex">
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          <label for="wound_occurrence">Date of Wound Occurrence:</label>
          <input type="date" id="wound_occurrence" @change=${this._calculateWoundDuration}>

          <label for="duration">Duration of Wound (months):</label>
          <input type="text" id="duration" readonly>

          <label for="wound_history">Wound Treatment History:</label>
          <textarea id="wound_history" placeholder="Enter treatment history..."></textarea>

          <label for="height">Height (m):</label>
          <input type="number" id="height" @change=${this._calculateBMI} step="0.01">

          <label for="weight">Weight (kg):</label>
          <input type="number" id="weight" @change=${this._calculateBMI}>

          <label for="bmi">Body Mass Index (BMI):</label>
          <input type="text" id="bmi" readonly>


          <div id="serum_container" style="display: none;">
            <label for="serum_albumin">Serum Albumin (g/L):</label>
            <input type="number" id="serum_albumin" value ="0">
          </div>


          <label for="blood_pressure">Blood Pressure (mmHg):</label>
          <input type="text" id="blood_pressure" placeholder="e.g., 120/80">

          <label for="blood_glucose">Blood Glucose (mmol/L):</label>
          <input type="number" id="blood_glucose">

          <label for="haemoglobin">Plasma Hemoglobin (g/dL):</label>
          <input type="number" id="haemoglobin">

          <label for="comorbidities">Comorbidities:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="diabetes" @change=${this._checkdiabetic}>
              <label for="diabetes">Diabetes</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="hypertension">
              <label for="hypertension">Hypertension</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="cardiac_failure">
              <label for="cardiac_failure">Cardiac Failure</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="kidney_disease"  @change=${this._checkkidney}>
              <label for="kidney_disease">Chronic Kidney Disease</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="malnutrition">
              <label for="malnutrition">Malnutrition</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="lung_disease">
              <label for="lung_disease">Chronic Lung Disease</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="immune_compromise" @change=${this._checkimune}>
              <label for="immune_compromise">Immune Compromise</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="haematopoietic_disease" @change=${this._checkhem}>
              <label for="haematopoietic_disease">Haematopoietic Disease</label>
            </div>
          </div>
          <div id="ankle" style="display: none;">
          <label for="ankle_pressure">Ankle Pressure (mmHg):</label>
          <input type="number" id="ankle_pressure" value="0" @change=${this._calculateABPI}>

          <label for="brachial_pressure">Brachial Pressure (mmHg):</label>
          <input type="number" id="brachial_pressure" value="0" @change=${this._calculateABPI}>

          <label for="abpi">Ankle-Brachial Pressure Index (ABPI):</label>
          <input type="text" id="abpi" value="0" readonly>

          <label for="toe_pressure">Toe Pressure Index (mmHg):</label>
          <input type="number" id="toe_pressure" value="0">
          </div>

          <div id="gfr1" style="display: none;">
            <label for="gfr">Glomerular Filtration Rate (ml/min):</label>
            <input type="number" id="gfr" value="0" >
          </div>
          <div class="checkbox-container" id="hivcheck" style="display: none;">
            <div class="checkbox-item">
              <input type="checkbox" id="hiv" @change=${this._checkHIV}>
              <label for="hiv">HIV:</label>
            </div>
          </div>


          <div id="cd4_container" style="display: none;">
            <label for="cd4_count">Clusters of Differentiation 4 (CD4) count (cells/mm3):</label>
            <input type="number" id="cd4_count" value="0">
          </div>



          <div class="checkbox-container" id="hemcheck" style="display: none;">
            <div class="checkbox-item">
              <input type="checkbox" id="haemophilia">
              <label for="haemophilia">Haemophilia:</label>
            </div>
          </div>

          <label for="monofilament">Monofilament Testing:</label>
          <input type="number" id="monofilament" placeholder="Areas tested negative (out of 10)">



          <div class="checkbox-container" id="nail" style="display: none;">
           <label>Nail Conditions:</label>
            <div class="checkbox-item">
              <input type="checkbox" id="ingrown_nail">
              <label for="ingrown_nail">Ingrown Nail</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="fungal_infection">
              <label for="fungal_infection">Fungal Infection</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="nail_pitting">
              <label for="nail_pitting">Infection of tissue</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="onchomycosis">
              <label for="onchomycosis">•	Nail atrophy</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="nail_discoloration">
              <label for="nail_discoloration">Deformed nail</label>
            </div>
          </div>




          <div class="checkbox-container" id="skin" style="display: none;">
            <label>Skin Conditions:</label>
            <div class="checkbox-item">
              <input type="checkbox" id="xerosis">
              <label for="xerosis">Xerosis</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="fissures">
              <label for="fissures">Fissures</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="eczema">
              <label for="eczema">tinea Pedis</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="psoriasis">
              <label for="psoriasis">plantar Wart</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="dermatitis">
              <label for="dermatitis">swelling</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="callus">
              <label for="dermatitis">callus</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="hardCorn">
              <label for="dermatitis">hardCorn</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="softCorn">
              <label for="dermatitis">softCorn</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="blister">
              <label for="dermatitis">blister</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="elevatedTemperature">
              <label for="dermatitis">elevated Temperature</label>
            </div>
          </div>

          <div class="checkbox-container" id="deformities" style="display: none;">
            <label>Foot Deformities:</label>

            <div class="checkbox-item">
              <input type="checkbox" id="hallux_valgus">
              <label for="hallux_valgus">Hallux Valgus</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="bunionette">
              <label for="bunionette">Bunionette</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="halluxRigidus">
              <label for="claw_toe">Challux Rigidus</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="malletToe">
              <label for="hammer_toe">mallet Toe</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="clawToe">
              <label for="flat_foot">claw Toe</label>
              </div>
              <div class="checkbox-item">
              <input type="checkbox" id="earlySignsOfCharcotFoot">
              <label for="flat_foot">early Signs Of Charcot Foot</label>
              </div>
              <div class="checkbox-item">
              <input type="checkbox" id="pronouncedCharcotFoot">
              <label for="flat_foot">pronounced Charcot Foot</label>
              </div>

          </div>


          <label>Medications & Treatments:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="immuno_suppressive">
              <label for="immuno_suppressive">Immuno-suppressive</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="cytotoxic">
              <label for="cytotoxic">Cytotoxic</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="antibiotic">
              <label for="antibiotic">corticosteroid</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="anticoagulant">
              <label for="anticoagulant">radiotherapy</label>
            </div>

          </div>


        </div>
        <button @click="${this._submitHistory}">Enter and Continue</button>
        <button @click="${this.logout}">Logout</button>
      </div>
    `;

  }

  firstUpdated() {
    this._calculateAge(); // Automatically calculate age after initial render
  }



  _calculateAge() {

    const ageElement = this.shadowRoot?.getElementById('age') as HTMLInputElement | null;

    if ( ageElement) {
      const dob = sessionStorage.getItem('patiendob');
      if (dob) {
        const birthDate = new Date(dob);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        ageElement.value = age.toString();

        // Handle flagging if age is outside normal range
        if (age < 5 || age > 70) {
          console.warn('Flag: Age outside normal range');
        }
      }
    }
  }

  _calculateWoundDuration() {
    const woundDateElement = this.shadowRoot?.getElementById('wound_occurrence') as HTMLInputElement | null;
    const durationElement = this.shadowRoot?.getElementById('duration') as HTMLInputElement | null;

    if (woundDateElement && durationElement) {
      const woundDate = woundDateElement.value;
      if (woundDate) {
        const woundDateObj = new Date(woundDate);
        const currentDate = new Date();
        const duration = (currentDate.getFullYear() - woundDateObj.getFullYear()) * 12
                       + (currentDate.getMonth() - woundDateObj.getMonth());
        durationElement.value = duration.toString();

        // Flag chronic wound if duration > 3 months
        if (duration > 3) {
          console.warn('Flag: Chronic wound (duration > 3 months)');
        }
      }
    }
  }

  _calculateBMI() {
    const heightElement = this.shadowRoot?.getElementById('height') as HTMLInputElement | null;
    const weightElement = this.shadowRoot?.getElementById('weight') as HTMLInputElement | null;
    const bmiElement = this.shadowRoot?.getElementById('bmi') as HTMLInputElement | null;
    const serumcontainer = this.shadowRoot?.getElementById('serum_container') as HTMLInputElement ;
    if (heightElement && weightElement && bmiElement) {
      const height = parseFloat(heightElement.value);
      const weight = parseFloat(weightElement.value);

      if (height && weight) {
        const bmi = weight / (height * height);
        bmiElement.value = bmi.toFixed(2);

        // Handle flagging if BMI is outside normal range
        if (bmi < 18.5 || bmi > 24.9) {
          console.warn('Flag: BMI outside normal range');
          if (bmi<18.5){

            serumcontainer.style.display = 'block';
          }
          else{serumcontainer.style.display = 'none';}
        }
      }
    }
  }

  _calculateABPI() {
    const anklePressureElement = this.shadowRoot?.getElementById('ankle_pressure') as HTMLInputElement | null;
    const brachialPressureElement = this.shadowRoot?.getElementById('brachial_pressure') as HTMLInputElement | null;
    const abpiElement = this.shadowRoot?.getElementById('abpi') as HTMLInputElement | null;

    if (anklePressureElement && brachialPressureElement && abpiElement) {
      const anklePressure = parseFloat(anklePressureElement.value);
      const brachialPressure = parseFloat(brachialPressureElement.value);

      if (anklePressure && brachialPressure) {
        const abpi = anklePressure / brachialPressure;
        abpiElement.value = abpi.toFixed(2);

        // Flags based on ABPI value
        if (abpi > 1.3) {
          console.warn('Toe Pressure Index: open up assessment section.');
        } else if (abpi >= 0.81 && abpi <= 0.9) {
          console.warn('Flag: Consider special precautions for wound management.');
        }
      }
    }
  }


  _checkHIV() {
    const hivElement = this.shadowRoot?.getElementById('hiv') as HTMLInputElement | null;
    const cd4Container = this.shadowRoot?.getElementById('cd4_container') as HTMLInputElement ;
    if (hivElement && hivElement.checked) {
      console.warn('Flag: Consider advanced assessment due to HIV status.');
      cd4Container.style.display = 'block'; // Show CD4 input
    }
    else {
      cd4Container.style.display = 'none'; // Show CD4 input
    }

  }
  _checkhem(){
    const hem = this.shadowRoot?.getElementById('haematopoietic_disease') as HTMLInputElement ;
    const hemi = this.shadowRoot?.getElementById('hemcheck') as HTMLInputElement ;
    if (hem && hem.checked) {
      //console.warn('Flag: Consider advanced assessment due to HIV status.');
      hemi.style.display = 'flex';
    }
    else {
      hemi.style.display = 'none';
    }
  }

  _checkimune(){
    const imune = this.shadowRoot?.getElementById('immune_compromise') as HTMLInputElement ;
    const hiv = this.shadowRoot?.getElementById('hivcheck') as HTMLInputElement ;
    if (imune && imune.checked) {
      //console.warn('Flag: Consider advanced assessment due to HIV status.');
      hiv.style.display = 'flex';
    }
    else {
      hiv.style.display = 'none';
    }

  }

  _checkkidney(){
    const kidney = this.shadowRoot?.getElementById('kidney_disease') as HTMLInputElement ;
    const gfr = this.shadowRoot?.getElementById('gfr1') as HTMLInputElement ;
    if (kidney && kidney.checked) {
      //console.warn('Flag: Consider advanced assessment due to HIV status.');
      gfr.style.display = 'block';
    }
    else {
      gfr.style.display = 'none';
    }

  }

  _checkdiabetic(){
    const diabetic = this.shadowRoot?.getElementById('diabetes') as HTMLInputElement ;
    const deformities = this.shadowRoot?.getElementById('deformities') as HTMLInputElement ;
    const ankle = this.shadowRoot?.getElementById('ankle') as HTMLInputElement ;
    const skin = this.shadowRoot?.getElementById('skin') as HTMLInputElement ;
    const nail = this.shadowRoot?.getElementById('nail') as HTMLInputElement ;



    if (diabetic && diabetic.checked) {
      //console.warn('Flag: Consider advanced assessment due to HIV status.');
      deformities.style.display = 'flex';
      nail.style.display = 'flex';
      skin.style.display = 'flex';
      ankle.style.display = 'block';

    }
    else {
      deformities.style.display = 'none';
      ankle.style.display = 'none';
      skin.style.display = 'none';
      nail.style.display = 'none';

    }

  }


  async _submitHistory() {
    // Here, handle the submission logic

    const woundPhotoPage = document.createElement('wound-photo-page');
// Fetching input values individually
const age = (this.shadowRoot?.getElementById('age') as HTMLInputElement)?.value;
const sex = (this.shadowRoot?.getElementById('sex') as HTMLSelectElement)?.value;
const woundOccurrence = (this.shadowRoot?.getElementById('wound_occurrence') as HTMLInputElement)?.value;
const duration = (this.shadowRoot?.getElementById('duration') as HTMLInputElement)?.value;
const woundHistory = (this.shadowRoot?.getElementById('wound_history') as HTMLTextAreaElement)?.value;
const height = (this.shadowRoot?.getElementById('height') as HTMLInputElement)?.value;
const weight = (this.shadowRoot?.getElementById('weight') as HTMLInputElement)?.value;
const bmi = (this.shadowRoot?.getElementById('bmi') as HTMLInputElement)?.value;
const serumAlbumin = (this.shadowRoot?.getElementById('serum_albumin') as HTMLInputElement)?.value;
const bloodPressure = (this.shadowRoot?.getElementById('blood_pressure') as HTMLInputElement)?.value;
const bloodGlucose = (this.shadowRoot?.getElementById('blood_glucose') as HTMLInputElement)?.value;
const haemoglobin = (this.shadowRoot?.getElementById('haemoglobin') as HTMLInputElement)?.value;

// Fetching comorbidities checkboxes individually
const diabetes = (this.shadowRoot?.getElementById('diabetes') as HTMLInputElement)?.checked;
const hypertension = (this.shadowRoot?.getElementById('hypertension') as HTMLInputElement)?.checked;
const cardiacFailure = (this.shadowRoot?.getElementById('cardiac_failure') as HTMLInputElement)?.checked;
const kidneyDisease = (this.shadowRoot?.getElementById('kidney_disease') as HTMLInputElement)?.checked;
const malnutrition = (this.shadowRoot?.getElementById('malnutrition') as HTMLInputElement)?.checked;
const lungDisease = (this.shadowRoot?.getElementById('lung_disease') as HTMLInputElement)?.checked;
const immuneCompromise = (this.shadowRoot?.getElementById('immune_compromise') as HTMLInputElement)?.checked;
const haematopoieticDisease = (this.shadowRoot?.getElementById('haematopoietic_disease') as HTMLInputElement)?.checked;

// Fetching foot deformities checkboxes individually
const halluxValgus = (this.shadowRoot?.getElementById('hallux_valgus') as HTMLInputElement)?.checked;
const bunionette = (this.shadowRoot?.getElementById('bunionette') as HTMLInputElement)?.checked;
const halluxRigidus = (this.shadowRoot?.getElementById('halluxRigidus') as HTMLInputElement)?.checked;
const malletToe = (this.shadowRoot?.getElementById('malletToe') as HTMLInputElement)?.checked;
const clawToe = (this.shadowRoot?.getElementById('clawToe') as HTMLInputElement)?.checked;
const earlySignsOfCharcotFoot = (this.shadowRoot?.getElementById('earlySignsOfCharcotFoot') as HTMLInputElement)?.checked;
const pronouncedCharcotFoot = (this.shadowRoot?.getElementById('pronouncedCharcotFoot') as HTMLInputElement)?.checked;

// Fetching nail conditions checkboxes individually
const ingrownNail = (this.shadowRoot?.getElementById('ingrown_nail') as HTMLInputElement)?.checked;
const fungalInfection = (this.shadowRoot?.getElementById('fungal_infection') as HTMLInputElement)?.checked;
const nailPitting = (this.shadowRoot?.getElementById('nail_pitting') as HTMLInputElement)?.checked;
const onychomycosis = (this.shadowRoot?.getElementById('onchomycosis') as HTMLInputElement)?.checked;
const nailDiscoloration = (this.shadowRoot?.getElementById('nail_discoloration') as HTMLInputElement)?.checked;

// Fetching skin conditions checkboxes individually
const xerosis = (this.shadowRoot?.getElementById('xerosis') as HTMLInputElement)?.checked;
const fissures = (this.shadowRoot?.getElementById('fissures') as HTMLInputElement)?.checked;
const eczema = (this.shadowRoot?.getElementById('eczema') as HTMLInputElement)?.checked;
const psoriasis = (this.shadowRoot?.getElementById('psoriasis') as HTMLInputElement)?.checked;
const dermatitis = (this.shadowRoot?.getElementById('dermatitis') as HTMLInputElement)?.checked;
const callus = (this.shadowRoot?.getElementById('callus') as HTMLInputElement)?.checked;
const hardCorn = (this.shadowRoot?.getElementById('hardCorn') as HTMLInputElement)?.checked;
const softCorn = (this.shadowRoot?.getElementById('softCorn') as HTMLInputElement)?.checked;
const blister = (this.shadowRoot?.getElementById('blister') as HTMLInputElement)?.checked;
const elevatedTemperature = (this.shadowRoot?.getElementById('elevatedTemperature') as HTMLInputElement)?.checked;

// Fetching additional medical inputs
const anklePressure = (this.shadowRoot?.getElementById('ankle_pressure') as HTMLInputElement)?.value;
const brachialPressure = (this.shadowRoot?.getElementById('brachial_pressure') as HTMLInputElement)?.value;
const abpi = (this.shadowRoot?.getElementById('abpi') as HTMLInputElement)?.value;
const toePressure = (this.shadowRoot?.getElementById('toe_pressure') as HTMLInputElement)?.value;
const gfr = (this.shadowRoot?.getElementById('gfr') as HTMLInputElement)?.value;
const cd4Count = (this.shadowRoot?.getElementById('cd4_count') as HTMLInputElement)?.value;
const monofilament = (this.shadowRoot?.getElementById('monofilament') as HTMLInputElement)?.value;
// Fetching HIV checkbox individually
const hiv = (this.shadowRoot?.getElementById('hiv') as HTMLInputElement)?.checked;
const heam = (this.shadowRoot?.getElementById('haemophilia') as HTMLInputElement)?.checked;

// Fetching medications & treatments checkboxes individually
const immunoSuppressive = (this.shadowRoot?.getElementById('immuno_suppressive') as HTMLInputElement)?.checked;
const cytotoxic = (this.shadowRoot?.getElementById('cytotoxic') as HTMLInputElement)?.checked;
const corticosteroid = (this.shadowRoot?.getElementById('antibiotic') as HTMLInputElement)?.checked;
const radiotherapy = (this.shadowRoot?.getElementById('anticoagulant') as HTMLInputElement)?.checked;

const inputsToCheck = [
  age, sex, duration, woundHistory, height, weight, bmi,
  serumAlbumin, bloodPressure, bloodGlucose, haemoglobin,
  anklePressure, brachialPressure, abpi, toePressure, gfr, cd4Count
];

const nurseid = sessionStorage.getItem("userId")
const IDP = sessionStorage.getItem("patientid")
//alert(IDP)

if (!IDP) {
  alert("Patient ID is missing or undefined!");  // Prevent sending the fetch request if IDP is invalid
}

let flag = false;
let count =0;
// Check if any of the values are undefined or empty
for (const input of inputsToCheck) {
  if ((input == undefined || input == '' )&&(flag == false)) {
      alert('Please fill in all fields.');

      flag = true;
  }
  count = count +1;
}
  if (flag == false){

    // Send data through fetch

      try {
        const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/create-patient-history', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nurseid,
            IDP, // Ensure the IDP key is matching
            age,
            sex,
            dateOfWound: woundOccurrence, // Match the variable names in the server
            woundTreatmentHist: woundHistory,
            height,
            weight,
            bmi,
            serumAlbumin,
            bloodPressure, // Match variable name on server
            plasmaHaemoglobin: haemoglobin,
            diabetes,
            hypertension,
            cardiacFailure,
            chronicKidneyDisease: kidneyDisease,
            malnutrition,
            chronicLungDisease: lungDisease,
            immuneCompromise,
            haematopoieticDisease,
            anklePressure,
            brachialPressure,
            toePressureIndex: toePressure, // Match server-side expectation
            glomerularFiltrationRate: gfr,
            hiv,
            cd4Count,
            haemophilia: heam, // Ensure key names are aligned
            monofilamentTesting: monofilament,
            ingrownNail,
            fungalInfection,
            infectionOfTissue: nailPitting, // Update as needed
            nailAtrophy: onychomycosis,
            deformedNail: nailDiscoloration,
            xerosis,
            fissures,
            tineaPedis:eczema,
            plantarWart:psoriasis,
            swelling:dermatitis,
            callus,
            hardCorn,
            softCorn,
            blister,
            elevatedTemperature,
            halluxValgus,
            bunionette,
            halluxRigidus,
            malletToe,
            clawToe,
            earlySignsOfCharcotFoot,
            pronouncedCharcotFoot,
            immunoSuppressive,
            cytotoxic,
            corticosteroid,
            radiotherapy,
            bloodglucose: bloodGlucose,
          }),
        });

        // Check status code
        if (!response.ok) {
          const errorData = await response.json(); // Parse error message if present
        //  alert(response.status)
          alert(errorData);
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        console.log('Patient history submitted successfully.');
        this.replaceWith(woundPhotoPage);
        console.log('Patient history submitted.');
      } catch (error) {
       // alert(error); // Show error message
        console.error('Error:', error);
      }



  }
  }



  async logout() {
    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/logout', {
        method: 'POST',

      });

      const data = await response.json();

      if (response.ok) {
       alert(data.message); // Display success message

        // Logic for consulting a patient or navigating to a patient consultation form
        const LOGIN = document.createElement('auth-page');
        sessionStorage.clear;
        this.replaceWith(LOGIN);
      } else {
       // alert(data.message); // Display error message
      }
    } catch (error) {
      alert(`Logout failed: ${error}`); // Handle network errors or unexpected issues
    }
  }


}
