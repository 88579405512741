import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

// Import the create-patient-page component
import './create-patient-page';
import './old-patient';

@customElement('patient-options-page')
export class PatientOptionsPage extends LitElement {

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
      padding: 20px;
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
    }

    .button {
      width: 300px;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin: 15px 0;
    }

    .button:hover {
      background-color: #1976d2;
    }
  `;

  // Call fetchUserName when the component is first updated


  render() {
    const Nurname = sessionStorage.getItem('nurName'); // Retrieve userId from sessionStorage
    const greetingMessage = Nurname ? `Hello ${Nurname}, Please select an option` : 'Hello USER, Please select an option';

    return html`
      <div class="container">
        <h2 id="greeting-label">${greetingMessage}</h2>
        <h3>New Patient</h3>
        <button class="button" @click=${this.createPatient}>Create Patient</button>

        <h3>Old Patient</h3>
        <button class="button" @click=${this.consultPatient}>Consult Patient</button>


        <button class="button" @click=${this.logout}>Logout</button>
      </div>
    `;
  }



  createPatient() {
    console.log('Create Patient button clicked');
    sessionStorage.setItem('lastPage', 'patient-options-page');
    // Replace the current patient-options-page with create-patient-page
    const createPatientPage = document.createElement('create-patient-page');
    this.replaceWith(createPatientPage);
  }

  async logout() {
    try {
      const response = await fetch('https://058d81c6-3000.inc1.devtunnels.ms/logout', {
        method: 'POST',

      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message); // Display success message

        // Logic for consulting a patient or navigating to a patient consultation form
        const LOGIN = document.createElement('auth-page');
        sessionStorage.clear;
        this.replaceWith(LOGIN);
      } else {
        alert(data.message); // Display error message
      }
    } catch (error) {
      alert(`Logout failed: ${error}`); // Handle network errors or unexpected issues
    }
  }

  consultPatient() {
    console.log('Consult Patient button clicked');
    sessionStorage.setItem('lastPage', 'patient-options-page');
    // Logic for consulting a patient or navigating to a patient consultation form
    const oldPatientPage = document.createElement('choose-patient-page');
    this.replaceWith(oldPatientPage);
  }

}




